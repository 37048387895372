@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
}

html {
  scroll-behavior: smooth;
  transition: all 1s ease-out;
  animation-name: sets_opacity;
  animation-duration: 1s;
}

div {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

div#root {
  min-width: 320px;
}

button {
  -webkit-tap-highlight-color: transparent;
  outline: none;
}

/* button#lightbulb {
  @apply bg-gray-500;
} */

.glider {
  margin-bottom: 4vh !important;
}

.draggable {
  overflow: auto;
}

.glider-slide {
  justify-content: space-between !important;
}

div.glider-track {
  width: 30%;
  padding: 0.5rem;

  @media all and (min-width: 820px) {
    padding: 6rem;
  }
}

div.project-item {
  box-shadow: -9px 16px 15px 5px #a9a9a9;
}

@keyframes from_left_to_right {
  0% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes sets_opacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 100;
  }
}

img#thug {
  -webkit-animation: cubic-bezier(0.22, 0.61, 0.36, 1);
  animation-name: from_left_to_right;
  animation-duration: 1.3s;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (min-width: 759px) {
  div#aboutme {
    display: flex;
    flex-direction: column;
  }

  div#aboutme div#myinfo {
    @apply m-auto;
    display: flex;
    flex-direction: column;
  }

  div#meus-projetos,
  div.meus-projetos {
    display: flex;
    @apply my-auto justify-center;
  }

  div#meus-projetos > div.project-item {
    @apply flex flex-col justify-between;
    width: 10vw;
  }

  div#meus-projetos > div.project-item:hover {
    transition: all 0.2s ease-in;
    cursor: ne-resizer;
  }

  div.splide {
    display: none;
  }
}

@media (min-width: 1200px) {
  div#meus-projetos {
    display: flex;
    @apply my-2;
    @apply justify-evenly;
  }

  div#meus-projetos > div > img {
    width: 10vw;
  }

  div#meus-projetos > div.project-item {
    width: 10vw;
  }

  div#aboutme {
    gap: 10px;
  }
}

@media (min-width: 1023px) {
  .glider {
    padding-left: 5vw !important;
    padding-right: 5vw !important;
  }
}

@media (max-width: 758px) {
  div.splide {
    @apply mx-auto mt-8 rounded-sm;
    box-shadow: -9px 16px 15px 5px #a9a9a9;
    width: 85vw;
  }

  div#meus-projetos {
    display: none;
  }

  div#aboutme div#myinfo {
    @apply flex flex-col gap-2;
  }

  img#thug {
    @apply mx-auto my-8;
    width: 50%;
  }
}

/* width */
::-webkit-scrollbar {
  background-color: #1f2937;
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px #1f2937; */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #fbbf24;
  @apply rounded-full;
}

svg#react-logo {
  animation: spin 4s infinite;
  animation-timing-function: linear;
}

div.splide__track {
  border-radius: 5px;
}

@keyframes spin {
  0% {
    transform: rotate(-360deg);
  }

  100% {
    transform: rotate(0);
  }
}

/* 
320px — 480px: Mobile devices
481px — 768px: iPads, Tablets
769px — 1024px: Small screens, laptops
1025px — 1200px: Desktops, large screens
1201px and more —  Extra large screens, TV 
*/
